import React, { useCallback, useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CRow,
  CSmartTable,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CDropdownItem,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
} from '@coreui/react-pro'
import { toast } from 'react-toastify'

import moment from 'moment'
import Bootbox from 'bootbox-react'
import { Link, useNavigate } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent.js'
import FixtureService from 'src/service/FixtureService'
import EditForm from './EditForm'
import CommonService from 'src/service/CommonService'
import Pusher from 'pusher-js'
import Loader from 'src/components/Loader'

const SquadImportFixture = (props) => {
  const [loading, setLoading] = useState(true)
  const [loader, setLoader] = useState(true)
  const [loaderText, setLoaderText] = useState(true)
  const [pageLoading, setPageLoading] = useState(false)
  const [visibleHorizontal, setVisibleHorizontal] = useState(false)
  const [selectedId, setSelectedId] = useState(0)

  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [users, setUsers] = useState({})
  const [squadFixtureIds, setSquadFixtureIds] = useState([])
  const [inProgressFixture, setInProgressFixture] = useState([])
  const navigate = useNavigate()
  const [gameType, setGameType] = useState()
  useEffect(() => {
    CommonService.getGameType().then((res) => {
      if (res.status === 200) {
        setGameType(res.data)
      }
    })
  }, [])

  const [details, setDetails] = useState([])
  // Delete Fixture code started here
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])

  const toggleAllCheckboxes = () => {
    const allIds = squadFixtureIds.map((user) => user.id)
    setSelectedCheckboxes((prevSelected) => (prevSelected.length === allIds.length ? [] : allIds))
  }
  var columns = [
    {
      label: 'FID',
      key: 'id',
      _style: { width: '10%' },
    },
    {
      key: 'round',
    },
    {
      label: 'Team Name',
      key: 'team_name',
    },
    {
      label: 'Team Code',
      key: 'team_code',
    },
    { label: 'Match Type', key: 'match_type', filter: false, sorter: false },
    { label: 'Start Date', filter: false, key: 'start_date' },
    { label: 'Start Time', filter: false, key: 'start_time' },
  ]

  if (gameType !== 1) {
    columns = [
      {
        label: 'FID',
        key: 'id',
        _style: { width: '10%' },
      },
      {
        key: 'round',
      },
      {
        label: 'Home Team',
        key: 'team_name',
      },
      {
        label: 'Home Team Code',
        key: 'team_code',
      },
      {
        label: 'Away Team',
        key: 'team2_name',
      },
      { label: 'Match Type', key: 'match_type', filter: false, sorter: false },
      { label: 'Start Date', filter: false, key: 'start_date' },
      { label: 'Start Time', filter: false, key: 'start_time' },
    ]
  }
  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 0:
        return 'warning'
      default:
        return 'primary'
    }
  }
  const toggleDetails = (index) => {
    setSelectedId(index)
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  const deleteFixture = (id) => {
    setLoading(true)
    const data = {}
    data.id = id
    const position = details.indexOf(id)
    let newDetails = details.slice()
    newDetails.splice(position, 1)
    setDetails(newDetails)
    // setUsers((previousEmployeeData) => previousEmployeeData.data.filter((data) => data.id !== id))
    FixtureService.deleteFixture(data).then((res) => {
      if (res.status === 200) {
        toast.dismiss()
        setUsers(res.data)
        ToastComponent(res.message, 'success')
        setLoading(false)
      }
    })
  }

  const fetchUsers = useCallback(async () => {
    setLoader(false)
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    try {
      const result = await FixtureService.getFixture(offset, itemsPerPage, activePage, params)
      const squadResult = await FixtureService.getSquadFixture()
      const inProgressFixtureResult = await FixtureService.getInProgressFixture()
      setUsers(result.data)
      setSquadFixtureIds(squadResult.data)
      setInProgressFixture(inProgressFixtureResult.data)
      if (inProgressFixtureResult.data && inProgressFixtureResult.data != null) {
        setLoader(true)
        setLoaderText(inProgressFixtureResult?.message)
      }
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }, [activePage, itemsPerPage, columnFilter, columnSorter])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  const hideEditDiv = () => {
    setVisibleHorizontal(false)
  }

  const toggleCheckbox = (id) => {
    setSelectedCheckboxes((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  // Delete All confirm box
  const [showDeleteAllConfirm, showShowDeleteAllConfirm] = useState(false)
  const showDeleteAllCofirmBox = () => {
    showShowDeleteAllConfirm(true)
  }
  const handleDeleteAllConfirm = () => {
    handleDeleteAllFixture()
    return showShowDeleteAllConfirm(false)
  }
  const handleDeleteAllCancel = () => {
    console.log('You clicked No!')
    return showShowDeleteAllConfirm(false)
  }
  const handleDeleteAllFixture = () => {
    const data = {
      fixturesIds: selectedCheckboxes, // Include the array of IDs here
    }
    FixtureService.deleteAllFixture(data).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
        setLoading(false)
        fetchUsers()
      }
    })
  }
  useEffect(() => {
    const pusher = new Pusher('2604209a1a0fdaa07dfc', {
      cluster: 'ap4',
    })
    const channel = pusher.subscribe('test-channel')
    channel.bind('test-event', (data) => {
      ToastComponent(data.message, 'red_error')
      fetchUsers()
    })

    const fixtureCompletionChannel = pusher.subscribe('fixture-completion-channel')
    fixtureCompletionChannel.bind('fixture-completion-event', (data) => {
      ToastComponent(data.message, 'success')
      fetchUsers()
    })
    return () => {
      // Unbind events and unsubscribe from channels
      channel.unbind_all()
      channel.unsubscribe()
      fixtureCompletionChannel.unbind_all()
      fixtureCompletionChannel.unsubscribe()
    }
  }, [fetchUsers])

  return loader ? (
    <Loader loaderText={loaderText} />
  ) : (
    <>
      <CRow>
        <CAccordion activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Manage Scores</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CSmartTable
                columns={columns}
                columnFilter={{
                  external: true,
                }}
                columnSorter={{
                  external: true,
                }}
                scopedColumns={{
                  round: (item) => <td>{item.round ?? '-'}</td>,
                  status: (item) => (
                    <td>
                      {item.status === 0 && <CBadge color="warning">Not Started</CBadge>}
                      {item.status === 1 && <CBadge color="danger">In-Progress</CBadge>}
                      {item.status === 2 && <CBadge color="success">Completed</CBadge>}
                      {item.status === 3 && <CBadge color="danger">Failed</CBadge>}
                      {item.status === 4 && <CBadge color="danger">In-Queue</CBadge>}
                    </td>
                  ),
                  grade: (item) => <td>{item.grade ?? ''}</td>,
                  start_date: (item) => <td>{moment(item.start_date).format('D.MM.YYYY')}</td>,
                  start_time: (item) => (
                    <td> {moment(item.start_time, ['HH:mm']).format('h:mm A')}</td>
                  ),
                }}
                items={users?.data}
                itemsPerPage={itemsPerPage}
                itemsPerPageSelect
                loading={loading}
                pagination={{
                  external: true,
                }}
                paginationProps={{
                  activePage: activePage,
                  pages: Math.ceil(users?.total / itemsPerPage) || 1,
                }}
                tableProps={{
                  hover: true,
                  responsive: true,
                }}
                onActivePageChange={(activePage) => setActivePage(activePage)}
                onColumnFilterChange={(filter) => {
                  setActivePage(1)
                  setColumnFilter(filter)
                }}
                onItemsPerPageChange={(itemsPerPage) => {
                  setActivePage(1)
                  setItemsPerPage(itemsPerPage)
                }}
                onSorterChange={(sorter) => setColumnSorter(sorter)}
              />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
      <Bootbox
        show={showDeleteAllConfirm}
        type={'confirm'}
        message={'Are you sure you want to delete all fixtures?'}
        onSuccess={handleDeleteAllConfirm}
        onCancel={handleDeleteAllCancel}
        onClose={handleDeleteAllCancel}
      />
    </>
  )
}

export default SquadImportFixture
