import { CBadge, CButton, CFormSelect, CFormSwitch } from '@coreui/react-pro'
import React from 'react'

const FantasyRoundResultEmail = (props) => {
  return (
    <>
      <table className="main-table table innertable">
        <thead>
          <tr>
            <th width="30%">Name</th>
            <th width="30%">Duration</th>
            <th width="30%">Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Fantasy Round Results & Reminder</td>
            <td>
              <CFormSelect
                aria-label="Select Structure"
                name="templete_id_50"
                className="form-control"
                value={props.hourValue['50']}
                onChange={(e) => props.handleHourChange(e, '50')}
                id="structure"
              >
                <option value="">Set Hours (After End of Round) </option>
                {[...Array(24).keys()].map((num) => (
                  <option key={num + 1} value={num + 1}>
                    {num + 1}
                  </option>
                ))}
              </CFormSelect>
            </td>
            <td>
              <CFormSwitch
                size="lg"
                label={props.enableDisableStatus['50'] ? 'Enabled' : 'Disabled'}
                id="formSwitchCheckDefaultLg11"
                checked={props.enableDisableStatus['50'] || false}
                onChange={(e) => props.handleEnableDisableChangeStatus(e, '50')}
              />
            </td>
            <td>
              <CButton onClick={() => props.handleSaveEmailNotificationSetting(50)}>Save</CButton>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default FantasyRoundResultEmail
