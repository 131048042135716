import React, { useState } from 'react'
import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CFormSwitch,
} from '@coreui/react-pro'
import Table from './Table'
import AddForm from './AddForm'
import GameNotificationService from 'src/service/GameNotificationService'
import { useEffect } from 'react'
import ShowEmailTemplate from './ShowEmailTemplate'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'
import SendAlertEmail from '../EmailAlerts/SendAlertEmail'
import FantasyRoundEmail from './FantasyRoundEmail'
import FantasyRoundResultEmail from './FantasyRoundResultEmail'
import TopTenManagerEmail from './TopTenManagerEmail'
import TopTenPlayerOverallEmail from './TopTenPlayerOverallEmail'
import CreatEmailMember from './CreatEmailMember'
import AnnounceFinalEmail from './AnnounceFinalEmail'
import EmailQuotaDetails from '../EmailAlerts/EmailQuotaDetails'
import EmailQuotaService from 'src/service/EmailQuotaService'
const GameNotification = () => {
  const [users, setUsers] = useState([])
  const [completedFixtureShow, setCompletedFixtureShow] = useState(false)
  const [tradingShow, setTradingShow] = useState(false)
  const [lockoutShow, setLockoutShow] = useState(false)
  const [getEmailNotificationSettingData, setGetEmailNotificationSettingData] = useState({})
  const [hourValue, setHourValue] = useState(
    useState({
      48: 24,
      50: 24,
    }),
  )

  const [hourValue48, setHourValue48] = useState(24)
  const [hourValue50, setHourValue50] = useState(24)

  const [enableDisableStatus, setEnableDisableStatus] = useState({
    48: false,
    50: false,
  })
  useEffect(() => {
    GameNotificationService.getAutoNotificationSetting()
      .then((res) => {
        if (res.status === 200) {
          setCompletedFixtureShow(res.data?.fixture_notification)
          setTradingShow(res.data?.trading_notification)
          setLockoutShow(res.data?.lockout_notification)
        }
      })
      .then(() => {
        CommonService.getEmailNotficationSetting().then((res) => {
          if (res.status === 200) {
            setHourValue(res.data?.duration)
            setGetEmailNotificationSettingData(res.data)
            const newStatus = res.data.reduce((acc, item) => {
              acc[item.template_id] = item.is_enable // assuming your response data has id and enabled fields
              return acc
            }, {})

            const newHourValue = res.data.reduce((acc, item) => {
              acc[item.template_id] = item.duration // assuming your response data has id and enabled fields
              return acc
            }, {})

            setHourValue(newHourValue)
            setEnableDisableStatus(newStatus)
          }
        })
      })
  }, [])

  const [alertCount, setAlertCount] = useState(24)
  useEffect(() => {
    EmailQuotaService.getEmailAlertCount().then((res) => {
      if (res.status === 200) {
        setAlertCount(res.data)
      }
    })
  }, [])
  const handleChangeStatus = (event, type) => {
    if (type === 'fixture') {
      setCompletedFixtureShow(event.target.checked)
    }
    if (type === 'trading') {
      setTradingShow(event.target.checked)
    }
    if (type === 'lockout') {
      setLockoutShow(event.target.checked)
    }
    const data = {}
    data.value = event.target.checked
    data.type = type
    GameNotificationService.changeAutoNotificationSetting(data).then((res) => {
      if (res.status === 200) {
        // setShow(res.data)
      }
    })
  }
  const handleEnableDisableChangeStatus = (event, id) => {
    setEnableDisableStatus({
      ...enableDisableStatus,
      [id]: event.target.checked,
    })

    const data = {}
    data.template_id = id
    data.is_enable = event.target.checked
    CommonService.changeEmailNotificationEnableDisable(data).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
      }
    })
  }
  // Save Duration in database
  const handleHourChange = (event, id) => {
    setHourValue({
      ...hourValue,
      [id]: event.target.value,
    })
  }
  const handleSaveEmailNotificationSetting = (template_id) => {
    const data = {}
    data.template_id = template_id
    data.duration = hourValue
    CommonService.saveEmailNotificationSetting(data).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
      }
    })
  }
  return (
    <CRow>
      {/* <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Email Alerts Sent</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <SendAlertEmail />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion> */}
      <EmailQuotaDetails />
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Fantasy Round Reminder - {alertCount?.fantasyRoundReminderCount}</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <FantasyRoundEmail
              enableDisableStatus={enableDisableStatus}
              handleEnableDisableChangeStatus={handleEnableDisableChangeStatus}
              setHourValue={setHourValue}
              hourValue={hourValue}
              handleHourChange={handleHourChange}
              handleSaveEmailNotificationSetting={handleSaveEmailNotificationSetting}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Fantasy Round Results & Reminder - {alertCount?.fantasyResultCount}</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <FantasyRoundResultEmail
              enableDisableStatus={enableDisableStatus}
              handleEnableDisableChangeStatus={handleEnableDisableChangeStatus}
              setHourValue={setHourValue}
              hourValue={hourValue}
              handleHourChange={handleHourChange}
              handleSaveEmailNotificationSetting={handleSaveEmailNotificationSetting}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Top 10 Managers (Overall) - {alertCount?.getTopManagerCount}</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <TopTenManagerEmail
              enableDisableStatus={enableDisableStatus}
              handleEnableDisableChangeStatus={handleEnableDisableChangeStatus}
              setHourValue={setHourValue}
              hourValue={hourValue}
              handleHourChange={handleHourChange}
              handleSaveEmailNotificationSetting={handleSaveEmailNotificationSetting}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Top 10 Players (Overall) - {alertCount?.getTopPlayerCount}</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <TopTenPlayerOverallEmail
              enableDisableStatus={enableDisableStatus}
              handleEnableDisableChangeStatus={handleEnableDisableChangeStatus}
              setHourValue={setHourValue}
              hourValue={hourValue}
              handleHourChange={handleHourChange}
              handleSaveEmailNotificationSetting={handleSaveEmailNotificationSetting}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Announce Final Result - {alertCount?.finalResultCount}</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <AnnounceFinalEmail
              enableDisableStatus={enableDisableStatus}
              handleEnableDisableChangeStatus={handleEnableDisableChangeStatus}
              setHourValue={setHourValue}
              hourValue={hourValue}
              handleHourChange={handleHourChange}
              handleSaveEmailNotificationSetting={handleSaveEmailNotificationSetting}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Email Members - {alertCount?.gameMemberEmailCount}</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <CreatEmailMember setUsers={setUsers} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      {/* <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Create Notification</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <AddForm setUsers={setUsers} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>

      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Manage Notifications</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <Table users={users} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1} alwaysOpen>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Game Notifications</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <CFormSwitch
              size="lg"
              label="Completed Fixtures"
              id="fixture"
              // checked={isRadio === item.id}
              checked={completedFixtureShow ? true : false}
              onChange={(e) => handleChangeStatus(e, 'fixture')}
            />
            <br></br>
            <CFormSwitch
              size="lg"
              label="Trading Window Open"
              id="trading"
              // checked={isRadio === item.id}
              checked={tradingShow ? true : false}
              onChange={(e) => handleChangeStatus(e, 'trading')}
            />
            <br></br>
            <CFormSwitch
              size="lg"
              label="Game Lockout"
              id="lockout"
              // checked={isRadio === item.id}
              checked={lockoutShow ? true : false}
              onChange={(e) => handleChangeStatus(e, 'lockout')}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion> */}
    </CRow>
  )
}

export default GameNotification
