import {
  CCol,
  CDatePicker,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CLink,
  CLoadingButton,
  CTimePicker,
} from '@coreui/react-pro'

import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import RoundService from 'src/service/RoundService'
import ToastComponent from 'src/components/common/TaostComponent'

const EditForm = (props) => {
  const [roundDetail, setRoundDetail] = useState()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [lockoutStartTime, setLockoutStartTime] = useState('')
  const [lockoutEndTime, setLockoutEndTime] = useState('')

  useEffect(() => {
    if (props.selectedId === props.roundId) {
      RoundService.getRoundDetail(props.roundId)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.start_date !== '0000-00-00 00:00:00') {
              setStartDate(res.data.start_date)
              setLockoutStartTime(moment(res.data.start_date).format('h:mm A'))
            }

            if (res.data.end_date !== '0000-00-00 00:00:00') {
              setEndDate(res.data.end_date)
              setLockoutEndTime(moment(res.data.end_date).format('h:mm A'))
            }

            setRoundDetail(res.data)
          }
        })
        .catch((e) => {
          ToastComponent(e.response?.data?.message, 'error')
        })
    }
  }, [props.selectedId, props.roundId])

  const [loader, setLoader] = useState(false)

  const validationSchema = Yup.object().shape({
    round: Yup.string().required('Round is required'),
    start_date: Yup.date().required('Start date is required'),
    end_date: Yup.date()
      .required('End date is required')
      .min(Yup.ref('start_date'), 'End date must be later than start date'),
    start_time: Yup.string().required('Start time is required'),
    end_time: Yup.string()
      .required('End time is required')
      .test('is-not-same', 'End time cannot be the same as start time', function (value) {
        const { start_time, start_date, end_date } = this.parent

        // If dates are the same, check the time
        if (moment(start_date).isSame(end_date, 'day')) {
          return value !== start_time // Validate end_time is not same as start_time
        }

        // If dates are different, skip this check
        return true
      })
      .test('is-later', 'End time must be later than start time', function (value) {
        const { start_time, start_date, end_date } = this.parent

        // If dates are the same, validate the time difference
        if (moment(start_date).isSame(end_date, 'day')) {
          const startTimeParsed = moment(start_time, 'h:mm A')
          const endTimeParsed = moment(value, 'h:mm A')

          return endTimeParsed.isAfter(startTimeParsed) // Validate that end_time is after start_time
        }

        // If dates are different, skip this check
        return true
      }),
  })

  const formik = useFormik({
    initialValues: {
      round: roundDetail?.round,
      start_date: roundDetail?.start_date,
      end_date: roundDetail?.end_date,
      start_time: lockoutStartTime,
      end_time: lockoutEndTime,
      trade_restriction: roundDetail?.trade_restriction,
      is_free_trades: roundDetail?.is_free_trades,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data) => {
      const formattedStartTime = moment(lockoutStartTime, 'h:mm A').format('HH:mm')
      // const formattedEndTime = moment(lockoutEndTime, 'h:mm A').format('HH:mm')
      const formattedEndTime = moment(lockoutEndTime, ['H:mm']).format('H:mm')
      const payload = {
        round_id: props.roundId,
        round: data.round,
        start_date: startDate,
        end_date: endDate,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        trade_restriction: data.trade_restriction,
        is_free_trades: data.is_free_trades,
      }

      setLoader(true)
      RoundService.editRound(payload)
        .then((res) => {
          if (res.status === 200) {
            props.fetchUsers()
            props.toggleDetails(props.roundId)
            ToastComponent(res.message, 'success')
            setLoader(false)
          } else {
            setLoader(false)
            ToastComponent(res.message, 'error')
          }
        })
        .catch(() => {
          ToastComponent('Something Went Wrong. Please try again', 'error')
          setLoader(false)
        })
    },
  })

  const handleStartDate = (event) => {
    const dateFormat = moment(event, 'DD/MM/YYYY').format('YYYY-MM-DD')
    setStartDate(dateFormat)
    formik.setFieldValue('start_date', dateFormat)
    formik.validateField('end_date')
  }

  const handleEndDate = (event) => {
    const dateFormat = moment(event, 'DD/MM/YYYY').format('YYYY-MM-DD')
    setEndDate(dateFormat)
    formik.setFieldValue('end_date', dateFormat)
  }

  const handleLockoutStartTime = (event) => {
    const timeFormat = moment(event, 'H:mm')
    if (!timeFormat.isValid()) return
    const formattedTime = timeFormat.format('H:mm')
    setLockoutStartTime(formattedTime)
    formik.setFieldValue('start_time', formattedTime)
    formik.setTouched({
      ...formik.touched,
      start_time: true,
    })
  }

  const handleLockoutEndTime = (event) => {
    const timeFormat = moment(event, 'H:mm')
    if (!timeFormat.isValid()) return
    const formattedTime = timeFormat.format('H:mm')
    setLockoutEndTime(formattedTime)
    formik.setFieldValue('end_time', formattedTime)
    formik.setTouched({
      ...formik.touched,
      end_time: true,
    })
  }

  const handleTradeRestrictionChange = (e) => {
    const value = e.target.checked
    setRoundDetail((prev) => ({
      ...prev,
      trade_restriction: value,
    }))
    formik.setFieldValue('trade_restriction', value)
    formik.setTouched({
      ...formik.touched,
      trade_restriction: true,
    })
  }
  const handleFreeTradeChange = (e) => {
    const value = e.target.checked
    setRoundDetail((prev) => ({
      ...prev,
      is_free_trades: value,
    }))
    formik.setFieldValue('is_free_trades', value)
    formik.setTouched({
      ...formik.touched,
      is_free_trades: true,
    })
  }

  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={12}>
          <CFormLabel className="fw-bold" htmlFor="round">
            Round *
          </CFormLabel>
          <input
            type="text"
            name="round"
            className={
              'form-control' + (formik.errors.round && formik.touched.round ? ' is-invalid' : '')
            }
            id="validationServer01"
            placeholder="round"
            defaultValue={roundDetail?.round}
            onChange={formik.handleChange}
            disabled={true}
          />
          {formik.errors.round && formik.touched.round && (
            <CFormFeedback invalid>{formik.errors.round}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="grade">
            Round Start Date *
          </CFormLabel>
          <CDatePicker
            date={startDate}
            locale="en-US"
            name="start_date"
            placeholder={'Start Date'}
            onDateChange={handleStartDate}
            format={'dd/MM/yyyy'}
            cleaner={false}
          />
          {formik.errors.start_date && formik.touched.start_date && (
            <CFormFeedback invalid>{formik.errors.start_date}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="grade">
            Round Start Time *
          </CFormLabel>
          <CTimePicker
            locale="en-US"
            cleaner={false}
            time={lockoutStartTime}
            value={lockoutStartTime}
            seconds={false}
            className={formik.errors.start_time && formik.touched.start_time ? 'is-invalid' : ''}
            onTimeChange={(e) => {
              handleLockoutStartTime(e)
              formik.setTouched({
                ...formik.touched,
                start_time: true,
              })
            }}
          />
          {formik.errors.start_time && formik.touched.start_time && (
            <CFormFeedback invalid>{formik.errors.start_time}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="grade">
            Round End Date *
          </CFormLabel>
          <CDatePicker
            date={endDate}
            locale="en-US"
            name="end_date"
            placeholder={'End Date'}
            onDateChange={handleEndDate}
            format={'dd/MM/yyyy'}
            cleaner={false}
          />
          {formik.errors.end_date && formik.touched.end_date && (
            <CFormFeedback invalid>{formik.errors.end_date}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="grade">
            Round End Time *
          </CFormLabel>
          <CTimePicker
            locale="en-US"
            cleaner={false}
            time={lockoutEndTime}
            value={lockoutEndTime}
            seconds={false}
            className={formik.errors.end_time && formik.touched.end_time ? 'is-invalid' : ''}
            onTimeChange={(e) => {
              handleLockoutEndTime(e)
              formik.setTouched({
                ...formik.touched,
                end_time: true,
              })
            }}
          />
          {formik.errors.end_time && formik.touched.end_time && (
            <CFormFeedback invalid>{formik.errors.end_time}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormSwitch
            id="trade_restriction"
            label="Trade Restriction"
            checked={roundDetail?.trade_restriction}
            onChange={handleTradeRestrictionChange}
          />
        </CCol>
        <CCol md={3}>
          <CFormSwitch
            id="is_free_trades"
            label="Free Trade"
            checked={roundDetail?.is_free_trades}
            onChange={handleFreeTradeChange}
          />
          <CCol md={6}></CCol>
        </CCol>
        <CCol md={12}>
          <CLoadingButton type="submit" color="primary" loading={loader} disabled={loader}>
            Submit
          </CLoadingButton>
          <CLink
            className="text-decoration-none mx-2"
            onClick={() => props.toggleDetails(props.roundId)}
          >
            Cancel
          </CLink>
        </CCol>
      </CForm>
    </>
  )
}

export default EditForm
