import React, { useEffect, useState } from 'react'
import { CCol, CRow, CWidgetStatsC, CWidgetStatsF } from '@coreui/react-pro'
import { cilListRich, cilPeople, cilUserFollow } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import EmailQuotaService from 'src/service/EmailQuotaService'

const EmailQuotaDetails = () => {
  const [emailQuotaDetails, setEmailQuotaDetails] = useState({})
  const getQuotaDetails = () => {
    EmailQuotaService.getQuotaDetails().then((res) => {
      if (res.status === 200) {
        setEmailQuotaDetails(res.data)
      }
    })
  }
  useEffect(() => {
    getQuotaDetails()
  }, [])
  return (
    <>
      <CRow>
        <CCol xs={12} sm={6} lg={4}>
          <CWidgetStatsC
            color="info-gradient"
            icon={<CIcon icon={cilPeople} height={36} />}
            value={emailQuotaDetails?.quota_left}
            title="Quota Available"
            inverse
            progress={{ value: 75 }}
            className="mb-4"
          />
        </CCol>
        <CCol xs={12} sm={6} lg={4}>
          <CWidgetStatsC
            color="success"
            icon={<CIcon icon={cilUserFollow} height={36} />}
            value={emailQuotaDetails?.emails_sent}
            title="Emails Sent"
            inverse
            progress={{ value: 75 }}
            className="mb-4"
          />
        </CCol>
        <CCol xs={12} sm={6} lg={4}>
          <CWidgetStatsC
            color="warning-gradient"
            icon={<CIcon icon={cilListRich} height={36} />}
            value={emailQuotaDetails?.quota_left}
            title="Quota Left"
            inverse
            progress={{ value: 75 }}
            className="mb-4"
          />
        </CCol>
      </CRow>
    </>
  )
}

export default EmailQuotaDetails
