import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCollapse,
  CSmartTable,
} from '@coreui/react-pro'

import moment from 'moment'
const FantasyRoundEmailLog = ({
  users,
  setUsers,
  fetchUsers,
  setActivePage,
  setItemsPerPage,
  setColumnFilter,
  setColumnSorter,
  itemsPerPage,
  activePage,
  columnFilter,
  columnSorter,
}) => {
  const [loading, setLoading] = useState()
  const columns = [
    {
      label: 'SN',
      key: 'sn',
    },
    {
      label: 'Send Date',
      key: 'created_at_date',
    },
    {
      label: 'Send Time',
      key: 'created_at_time',
    },
    {
      label: 'Emails Sent',
      key: 'email_count',
    },
  ]

  useEffect(() => {
    const offset = itemsPerPage * activePage - itemsPerPage
    fetchUsers(offset, itemsPerPage, activePage, columnFilter, columnSorter)
  }, [activePage, columnFilter, columnSorter, itemsPerPage, fetchUsers])
  return (
    <>
      <CSmartTable
        columns={columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          sn: (item, index) => <td>{index + 1}</td>,
          created_at_date: (item) => <td>{moment(item.created_at).format('D.MM.YYYY')}</td>,
          created_at_time: (item) => <td>{moment(item.created_at, ['HH:mm']).format('h:mma')}</td>,
        }}
        items={users?.data}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        loading={loading}
        pagination={{
          external: true,
        }}
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(users?.total / itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage(1)
          setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1)
          setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => setColumnSorter(sorter)}
      />
    </>
  )
}

export default FantasyRoundEmailLog
