import React, { useEffect, useState } from 'react'
import { CButton, CSmartTable } from '@coreui/react-pro'
import Bootbox from 'bootbox-react'
import moment from 'moment'
import ToastComponent from 'src/components/common/TaostComponent.js'
import GameNotificationService from 'src/service/GameNotificationService'
import Notify from '../Notify'
import EmailQuotaService from 'src/service/EmailQuotaService'
import ViewMessage from './ViewMessage'

const SentMemberEmailLog = (props) => {
  const [loading, setLoading] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState({})
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [users, setUsers] = useState(props.users)
  const [details, setDetails] = useState([])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const columns = [
    { label: 'Member Name', key: 'full_name' },
    { label: 'Send Date', key: 'created_at' },
    { label: 'Send Time', key: 'send_time' },
    {
      key: 'show_details',
      label: 'Actions',
      _style: { width: '1%' },
      filter: false,
      sorter: false,
    },
  ]

  const deleteNotification = async (id) => {
    setLoading(true)
    try {
      await GameNotificationService.deleteNotification({ id })
      setUsers((prevUsers) => ({
        ...prevUsers,
        data: prevUsers.data.filter((user) => user.id !== id),
      }))
      setDetails(details.filter((detailId) => detailId !== id))
      ToastComponent('Notification deleted successfully', 'success')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    const offset = itemsPerPage * (activePage - 1)
    let params = new URLSearchParams()

    Object.keys(columnFilter).forEach((key) => params.append(key, columnFilter[key]))

    if (columnSorter?.column) {
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)
    }

    EmailQuotaService.getSentEmailLog(offset, itemsPerPage, activePage, params)
      .then((result) => {
        setUsers(result.data)
      })
      .finally(() => setLoading(false))
  }, [activePage, columnFilter, columnSorter, itemsPerPage])

  const [showConfirm, setShowConfirm] = useState(false)
  const [tableId, setTableId] = useState(null)

  const handleConfirm = () => {
    deleteNotification(tableId)
    setShowConfirm(false)
  }

  // Delete All confirm box
  const [showSendAllConfirm, showShowSendAllConfirm] = useState(false)
  const showSendAllCofirmBox = () => {
    showShowSendAllConfirm(true)
  }
  const handleSendAllConfirm = () => {
    handleSendEmailAll()
    return showShowSendAllConfirm(false)
  }
  const handleSendAllCancel = () => {
    return showShowSendAllConfirm(false)
  }

  const handleSendEmailAll = () => {
    setLoading(true)
    EmailQuotaService.sendAllEmails({ memberEmailIds: selectedCheckboxes })
      .then((res) => {
        if (res.status === 200) {
          ToastComponent(res.message, 'success')
          setSelectedCheckboxes([])
        }
      })
      .finally(() => setLoading(false))
  }

  const [viewMessageDetails, setViewMessageDetails] = useState('')
  const [modalIsOpen, setIsOpen] = useState(false)
  // Set Up Modal Box
  function openFromParent(message) {
    setIsOpen(true)
    setViewMessageDetails(message)
  }

  return (
    <>
      <CSmartTable
        columns={columns}
        columnFilter={{ external: true }}
        columnSorter={{ external: true }}
        scopedColumns={{
          created_at: (item) => <td>{moment(item.created_at).format('D.MM.YYYY')}</td>,
          send_time: (item) => <td>{moment(item.created_at, ['HH:mm']).format('h:mma')}</td>,
          show_details: (item) => (
            <td className="py-2">
              <CButton
                size="sm"
                className="ml-1"
                onClick={() => openFromParent(item.message)}
                color={'success'}
              >
                View
              </CButton>
            </td>
          ),
        }}
        items={users?.data || []}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        loading={loading}
        pagination={{ external: true }}
        paginationProps={{
          activePage,
          pages: Math.ceil(users?.total / itemsPerPage) || 1,
        }}
        tableProps={{ hover: true, responsive: true }}
        onActivePageChange={setActivePage}
        onColumnFilterChange={setColumnFilter}
        onItemsPerPageChange={setItemsPerPage}
        onSorterChange={setColumnSorter}
      />

      <Notify
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        handleYes={handleConfirm}
        handleNo={() => setShowConfirm(false)}
        text="Are you sure you want to delete this?"
      />

      <Bootbox
        show={showSendAllConfirm}
        type="confirm"
        message="Are you sure you want to send all users emails?"
        onSuccess={handleSendAllConfirm}
        onCancel={handleSendAllCancel}
        onClose={handleSendAllCancel}
      />
      <ViewMessage
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        viewMessageDetails={viewMessageDetails}
      />
    </>
  )
}

export default SentMemberEmailLog
