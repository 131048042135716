import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { agent } from '../../utils/agent'
import CommonService from 'src/service/CommonService'
const API_URL = process.env.REACT_APP_API_URL

const EmailQuotaSuccess = () => {
  const location = useLocation()
  const [sessionDetails, setSessionDetails] = useState(null)
  const sessionId = new URLSearchParams(location.search).get('session_id')
  const token = JSON.parse(localStorage.getItem('token'))
  const navigate = useNavigate()
  var gameId = localStorage.getItem('gameId')
  useEffect(() => {
    if (sessionId) {
      // Fetch session details from the backend
      fetch(`${API_URL}get-session-details?session_id=${sessionId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'x-game-info': `${gameId}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setSessionDetails(data)
          data.session_id = sessionId
          // Save payment data to your backend
          fetch(`${API_URL}save-email-quota-payment`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              'x-game-info': `${gameId}`,
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                navigate('/purchase-email-alerts')
                console.log('Payment saved successfully:', data)
              } else {
                console.error('Failed to save payment:', data)
              }
            })
            .catch((error) => {
              console.error('Error saving payment:', error)
            })
        })
        .catch((error) => {
          console.error('Error fetching session details:', error)
        })
    }
  }, [sessionId])

  return (
    <div>
      <p>Processing...</p>
    </div>
  )
}

export default EmailQuotaSuccess
