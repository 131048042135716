import React, { useCallback, useEffect, useState } from 'react'
import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from '@coreui/react-pro'
import debounce from 'lodash.debounce'
import FantasyRoundEmailLog from './FantasyRoundEmailLog'
import EmailQuotaService from 'src/service/EmailQuotaService'
import SentMemberEmailLog from './SentMemberEmailLog'
import EmailQuotaDetails from '../EmailAlerts/EmailQuotaDetails'

const EmailTrack = () => {
  const [users, setUsers] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [columnFilter, setColumnFilter] = useState({})
  const [columnSorter, setColumnSorter] = useState({})
  const [emailType, setEmailType] = useState(0)
  const fetchUsers = useCallback(
    debounce(async () => {
      try {
        const offset = itemsPerPage * (activePage - 1)
        const params = new URLSearchParams()

        Object.entries(columnFilter).forEach(([key, value]) => {
          if (value) params.append(key, value) // Avoid appending empty values
        })

        if (columnSorter?.column) {
          params.append('sort', `${columnSorter.column}%${columnSorter.state}`)
        }

        const result = await EmailQuotaService.getEmailLogs(
          emailType,
          offset,
          itemsPerPage,
          activePage,
          params,
        )

        setUsers(result.data)
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    }, 500), // Debounce to prevent multiple API calls on repeated clicks
    [emailType, activePage, itemsPerPage, columnFilter, columnSorter], // Added emailType to dependencies
  )

  const [alertCount, setAlertCount] = useState(24)
  useEffect(() => {
    EmailQuotaService.getEmailAlertCount().then((res) => {
      if (res.status === 200) {
        setAlertCount(res.data)
      }
    })
  }, [])
  return (
    <>
      <EmailQuotaDetails />
      <CRow>
        <CAccordion>
          {/* <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Email Alerts Sent</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <FantasyRoundEmailLog
                users={users}
                setUsers={setUsers}
                fetchUsers={fetchUsers}
                setActivePage={setActivePage}
                setItemsPerPage={setItemsPerPage}
                setColumnFilter={setColumnFilter}
                setColumnSorter={setColumnSorter}
                itemsPerPage={itemsPerPage}
                activePage={activePage}
                columnFilter={columnFilter}
                columnSorter={columnSorter}
              />
            </CAccordionBody>
          </CAccordionItem> */}
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>
              {' '}
              <strong>Fantasy Round Reminder - {alertCount?.fantasyRoundReminderCount}</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <FantasyRoundEmailLog
                users={users}
                setUsers={setUsers}
                fetchUsers={fetchUsers}
                setActivePage={setActivePage}
                setItemsPerPage={setItemsPerPage}
                setColumnFilter={setColumnFilter}
                setColumnSorter={setColumnSorter}
                itemsPerPage={itemsPerPage}
                activePage={activePage}
                columnFilter={columnFilter}
                columnSorter={columnSorter}
              />
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={3}>
            <CAccordionHeader>
              {' '}
              <strong>Fantasy Round Results & Reminder - {alertCount?.fantasyResultCount}</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <FantasyRoundEmailLog
                users={users}
                setUsers={setUsers}
                fetchUsers={fetchUsers}
                setActivePage={setActivePage}
                setItemsPerPage={setItemsPerPage}
                setColumnFilter={setColumnFilter}
                setColumnSorter={setColumnSorter}
                itemsPerPage={itemsPerPage}
                activePage={activePage}
                columnFilter={columnFilter}
                columnSorter={columnSorter}
              />
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem
            itemKey={4}
            onClick={() => {
              setEmailType(4)
              fetchUsers(4)
            }}
          >
            <CAccordionHeader>
              {' '}
              <strong>Top 10 Managers (Overall) - {alertCount?.getTopManagerCount}</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <FantasyRoundEmailLog
                users={users}
                setUsers={setUsers}
                fetchUsers={fetchUsers}
                setActivePage={setActivePage}
                setItemsPerPage={setItemsPerPage}
                setColumnFilter={setColumnFilter}
                setColumnSorter={setColumnSorter}
                itemsPerPage={itemsPerPage}
                activePage={activePage}
                columnFilter={columnFilter}
                columnSorter={columnSorter}
              />
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem
            itemKey={5}
            onClick={() => {
              setEmailType(3)
              fetchUsers(3)
            }}
          >
            <CAccordionHeader>
              {' '}
              <strong>Top 10 Players (Overall) - {alertCount?.getTopPlayerCount}</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <FantasyRoundEmailLog
                users={users}
                setUsers={setUsers}
                fetchUsers={fetchUsers}
                setActivePage={setActivePage}
                setItemsPerPage={setItemsPerPage}
                setColumnFilter={setColumnFilter}
                setColumnSorter={setColumnSorter}
                itemsPerPage={itemsPerPage}
                activePage={activePage}
                columnFilter={columnFilter}
                columnSorter={columnSorter}
              />
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem
            itemKey={6}
            onClick={() => {
              setEmailType(6)
              fetchUsers(6)
            }}
          >
            <CAccordionHeader>
              {' '}
              <strong>Announce Final Result - {alertCount?.finalResultCount}</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <FantasyRoundEmailLog
                users={users}
                setUsers={setUsers}
                fetchUsers={fetchUsers}
                setActivePage={setActivePage}
                setItemsPerPage={setItemsPerPage}
                setColumnFilter={setColumnFilter}
                setColumnSorter={setColumnSorter}
                itemsPerPage={itemsPerPage}
                activePage={activePage}
                columnFilter={columnFilter}
                columnSorter={columnSorter}
              />
            </CAccordionBody>
          </CAccordionItem>
          <CAccordionItem itemKey={7}>
            <CAccordionHeader>
              {' '}
              <strong>Email Members - {alertCount?.gameMemberEmailCount}</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <SentMemberEmailLog />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default EmailTrack
