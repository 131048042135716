import React, { useCallback, useEffect, useState } from 'react'
import { CButton, CSmartTable, CCard, CCardBody, CCardHeader } from '@coreui/react-pro'
import Bootbox from 'bootbox-react'
import moment from 'moment'
import ToastComponent from 'src/components/common/TaostComponent.js'
import GameNotificationService from 'src/service/GameNotificationService'
import Notify from '../Notify'
import EmailQuotaService from 'src/service/EmailQuotaService'

const EmailMemberListing = ({
  details,
  setDetails,
  selectedCheckboxes,
  setSelectedCheckboxes,
  users,
  setUsers,
  fetchUsers,
  setActivePage,
  setItemsPerPage,
  setColumnFilter,
  setColumnSorter,
  itemsPerPage,
  activePage,
  columnFilter,
  columnSorter,
  sentEmailIds,
}) => {
  const [loading, setLoading] = useState(false)
  const toggleAllCheckboxes = () => {
    const allSelectableIds = users?.data.map((user) => user.id) || []
    setSelectedCheckboxes((prevSelected) =>
      prevSelected.length === allSelectableIds.length ? [] : allSelectableIds,
    )
  }
  const columns = [
    {
      key: 'send_email',
      label: (
        <input
          type="checkbox"
          name="send_all"
          ref={(input) => {
            if (input) {
              input.indeterminate =
                selectedCheckboxes.length > 0 &&
                selectedCheckboxes.length < (users?.data.length || 0)
            }
          }}
          checked={users?.data.length > 0 && selectedCheckboxes.length === users?.data.length}
          onChange={toggleAllCheckboxes}
        />
      ),
      filter: false,
      sorter: false,
    },
    { label: 'Member Name', key: 'full_name' },
    { label: 'Send Date', key: 'created_at' },
    { label: 'Send Time', key: 'send_time' },
  ]
  const deleteNotification = async (id) => {
    setLoading(true)
    try {
      await GameNotificationService.deleteNotification({ id })
      setUsers((prevUsers) => ({
        ...prevUsers,
        data: prevUsers.data.filter((user) => user.id !== id),
      }))
      setDetails(details.filter((detailId) => detailId !== id))
      ToastComponent('Notification deleted successfully', 'success')
    } finally {
      setLoading(false)
    }
  }

  const [showConfirm, setShowConfirm] = useState(false)
  const [tableId, setTableId] = useState(null)

  const handleConfirm = () => {
    deleteNotification(tableId)
    setShowConfirm(false)
  }

  // Delete All confirm box
  const [showSendAllConfirm, showShowSendAllConfirm] = useState(false)
  const showSendAllCofirmBox = () => {
    showShowSendAllConfirm(true)
  }
  const handleSendAllConfirm = () => {
    handleSendEmailAll()
    return showShowSendAllConfirm(false)
  }
  const handleSendAllCancel = () => {
    return showShowSendAllConfirm(false)
  }

  const handleSendEmailAll = () => {
    setLoading(true)
    EmailQuotaService.sendAllEmails({ memberEmailIds: selectedCheckboxes })
      .then((res) => {
        if (res.status === 200) {
          ToastComponent(res.message, 'success')
          setSelectedCheckboxes([])
          fetchUsers()
        }
      })
      .finally(() => setLoading(false))
  }

  // Delete All confirm box
  const [showDeleteAllConfirm, showShowDeleteAllConfirm] = useState(false)
  const showDeleteAllCofirmBox = () => {
    showShowDeleteAllConfirm(true)
  }
  const handleDeleteAllConfirm = () => {
    handleDeleteAllMember()
    return showShowDeleteAllConfirm(false)
  }
  const handleDeleteAllCancel = () => {
    console.log('You clicked No!')
    return showShowDeleteAllConfirm(false)
  }
  const handleDeleteAllMember = () => {
    const data = {
      memberIds: selectedCheckboxes, // Include the array of IDs here
    }
    EmailQuotaService.deleteAllMembers(data).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
        fetchUsers()
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    const offset = itemsPerPage * activePage - itemsPerPage
    fetchUsers(offset, itemsPerPage, activePage, columnFilter, columnSorter)
  }, [activePage, columnFilter, columnSorter, itemsPerPage, fetchUsers])

  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>Send</CCardHeader>
        <CCardBody>
          <CButton
            color="success"
            className="mb-3"
            onClick={showSendAllCofirmBox}
            disabled={selectedCheckboxes.length === 0}
          >
            Send
          </CButton>{' '}
          <CButton
            color="danger"
            className="mb-3 pr-2"
            onClick={showDeleteAllCofirmBox}
            disabled={selectedCheckboxes.length === 0}
          >
            Delete
          </CButton>
          <CSmartTable
            columns={columns}
            columnFilter={{ external: true }}
            columnSorter={{ external: true }}
            scopedColumns={{
              send_email: (item) => (
                <td>
                  {!sentEmailIds.some((data) => data.id === item.id) && (
                    <input
                      type="checkbox"
                      name="delete"
                      value={item.id}
                      checked={selectedCheckboxes.includes(item.id)}
                      onChange={() =>
                        setSelectedCheckboxes((prev) =>
                          prev.includes(item.id)
                            ? prev.filter((id) => id !== item.id)
                            : [...prev, item.id],
                        )
                      }
                    />
                  )}
                </td>
              ),
              created_at: (item) => <td>{moment(item.created_at).format('D.MM.YYYY')}</td>,
              send_time: (item) => <td>{moment(item.created_at, ['HH:mm']).format('h:mma')}</td>,
              show_details: (item) => (
                <td className="py-2">
                  <CButton
                    size="sm"
                    color="danger"
                    className="ml-1"
                    onClick={() => {
                      setShowConfirm(true)
                      setTableId(item.id)
                    }}
                  >
                    Send
                  </CButton>
                </td>
              ),
            }}
            items={users?.data || []}
            itemsPerPage={itemsPerPage}
            itemsPerPageSelect
            loading={loading}
            pagination={{ external: true }}
            paginationProps={{
              activePage,
              pages: Math.ceil(users?.total / itemsPerPage) || 1,
            }}
            tableProps={{ hover: true, responsive: true }}
            onActivePageChange={setActivePage}
            onColumnFilterChange={setColumnFilter}
            onItemsPerPageChange={setItemsPerPage}
            onSorterChange={setColumnSorter}
          />
        </CCardBody>
      </CCard>

      <Notify
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        handleYes={handleConfirm}
        handleNo={() => setShowConfirm(false)}
        text="Are you sure you want to delete this?"
      />

      <Bootbox
        show={showSendAllConfirm}
        type="confirm"
        message="Are you sure you want to send this email to all selected members?"
        onSuccess={handleSendAllConfirm}
        onCancel={handleSendAllCancel}
        onClose={handleSendAllCancel}
      />

      <Bootbox
        show={showDeleteAllConfirm}
        type={'confirm'}
        message={'Are you sure you want to delete selected messages?'}
        onSuccess={handleDeleteAllConfirm}
        onCancel={handleDeleteAllCancel}
        onClose={handleDeleteAllCancel}
      />
    </>
  )
}

export default EmailMemberListing
