import React, { useCallback, useEffect, useState } from 'react'
import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CAlert,
} from '@coreui/react-pro'
import Table from './Table'
import AddForm from './AddForm'
import GradeService from 'src/service/GradeService'
const Grade = () => {
  const [users, setUsers] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [columnFilter, setColumnFilter] = useState({})
  const [columnSorter, setColumnSorter] = useState({})
  const [gameType, setGameType] = useState({})
  const fetchUsers = useCallback(async () => {
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    try {
      const result = await GradeService.getGrades(offset, itemsPerPage, activePage, params)
      setUsers(result.data)
      setGameType(result.game_type)
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
    }
  }, [activePage, itemsPerPage, columnFilter, columnSorter])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])
  return (
    <CRow>
      <CAccordion activeItemKey={1} alwaysOpen>
        {gameType == 4 && (
          <CAlert color="danger">Restriction: You can create a maximum of 1 Comp Name only</CAlert>
        )}
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Create Comp (Grade)</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <AddForm fetchUsers={fetchUsers} />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Manage Comps</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <Table
              users={users}
              setUsers={setUsers}
              fetchUsers={fetchUsers}
              setActivePage={setActivePage}
              setItemsPerPage={setItemsPerPage}
              setColumnFilter={setColumnFilter}
              setColumnSorter={setColumnSorter}
              itemsPerPage={itemsPerPage}
              activePage={activePage}
              columnFilter={columnFilter}
              columnSorter={columnSorter}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default Grade
