import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import FixtureService from 'src/service/FixtureService'
import CommonService from 'src/service/CommonService'

const SquadImport = () => {
  const [file, setFile] = useState()
  const [array, setArray] = useState([])
  const [gameType, setGameType] = useState()
  const [errorMessages, setErrorMessages] = useState([]) // State to manage error messages
  const fileReader = new FileReader()

  useEffect(() => {
    CommonService.getGameType().then((res) => {
      if (res.status === 200) {
        setGameType(res.data)
      }
    })
  }, [])

  const handleDownloadCsv = () => {
    FixtureService.squadPlayerExport()
  }

  // Import squad code

  useEffect(() => {
    if (array.length > 0) {
      const squadPlayerCSVData = { playerData: array }
      const handleResponse = (res) => {
        if (res.success) {
          ToastComponent(res.message, 'success')
          setErrorMessages([]) // Clear error messages on success
        } else {
          const errors =
            res.errors && Array.isArray(res.errors)
              ? res.errors
              : [res.message || 'Something went wrong.']
          setErrorMessages(errors) // Update error messages
        }
      }

      FixtureService.importSquadPlayer(squadPlayerCSVData).then(handleResponse)
    }
  }, [array])

  const handleOnChange = (e) => {
    setFile(e.target.files[0])
  }

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',')
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n')

    const array = csvRows.map((i) => {
      const values = i.split(',')
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index]
        return object
      }, {})
      return obj
    })

    setArray(array)
  }

  const handleImportPlayer = (e) => {
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result
        csvFileToArray(text)
      }
      fileReader.readAsText(file)
    } else {
      ToastComponent('Please upload a CSV file.', 'error')
    }
  }
  return (
    <>
      {errorMessages.length > 0 && (
        <div className="error-list">
          {errorMessages.map((error, index) => (
            <>
              <CAlert color="danger" key={index}>
                {error}
              </CAlert>
            </>
          ))}
        </div>
      )}
      <CRow className="pt-2">
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Squad Import</strong>
            </CCardHeader>

            <CCardBody>
              <CRow>
                <CCol md={6}>
                  <CFormInput
                    type="file"
                    id="playerFileId"
                    name="playerFileName"
                    accept={'.csv'}
                    onChange={handleOnChange}
                  />
                </CCol>
                <CCol md={6}>
                  <CLoadingButton color="success" variant="outline" onClick={handleImportPlayer}>
                    Run Import
                  </CLoadingButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Download Squad Import File</strong>
              <br></br>
            </CCardHeader>

            <CCardBody>
              <CRow>
                <CCol md={12}>
                  <p>
                    <CButton color={'primary'} variant="outline" onClick={handleDownloadCsv}>
                      Download Squad Import File
                    </CButton>
                  </p>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default SquadImport
