import { CButton, CModal, CModalBody, CModalFooter, CCol, CFormLabel } from '@coreui/react-pro'
import React from 'react'

const ViewMessage = (props) => {
  return (
    <>
      <CModal visible={props.modalIsOpen} size="lg">
        <CModalBody>
          <CCol md={12}>
            <CFormLabel htmlFor="title">
              {' '}
              <strong> Message</strong>
            </CFormLabel>
            :&nbsp;{props.viewMessageDetails}
          </CCol>
        </CModalBody>

        <CModalFooter>
          <CButton color="primary" onClick={(e) => props.setIsOpen(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default ViewMessage
