import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL
const getTransactions = async (offset, itemsPerPage, activePage, params) => {
  if (offset) {
    const response = await agent.get(
      `${API_URL}/email-quota/listing?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
    )
    return response.data
  } else {
    const response = await agent.get(`${API_URL}email-quota/listing`)
    return response.data
  }
}

const getMemberList = async () => {
  const response = await agent.get(API_URL + 'email-quota/getMemberList')
  return response.data
}

const saveEmailMembers = async (data) => {
  const response = await agent.post(API_URL + 'email-quota/saveEmailMembers', data)
  return response.data
}
const getEmailMember = async (data) => {
  const response = await agent.get(API_URL + 'email-quota/getEmailMember')
  return response.data
}

const sendAllEmails = async (data) => {
  const response = await agent.post(API_URL + 'email-quota/sendAllEmails', data)
  return response.data
}

const getSentEmailLog = async (data) => {
  const response = await agent.get(API_URL + 'email-quota/getSentEmailLog')
  return response.data
}
const getMemberMessage = async (data) => {
  const response = await agent.get(API_URL + 'email-quota/getMemberMessage')
  return response.data
}

const topPlayerOveall = async (rd = '', team = '', currentPage = '', perPage = '') => {
  const response = await agent.get(
    API_URL +
      `email-quota/topPlayerOveall?rd=${rd}&team=${team}&page=${currentPage}&perPage=${currentPage}`,
  )
  return response.data
}
const topManagerOverall = async (rd = '', team = '', currentPage = '', perPage = '') => {
  const response = await agent.get(
    API_URL +
      `email-quota/topManagerOverall?rd=${rd}&team=${team}&page=${currentPage}&perPage=${currentPage}`,
  )
  return response.data
}

const deleteAllMembers = async (data) => {
  const response = await agent.post(API_URL + 'email-quota/deleteAllMembers', data)
  return response.data
}

const getQuotaDetails = async () => {
  const response = await agent.get(API_URL + 'email-quota/getQuotaDetails')
  return response.data
}

const getEmailLogs = async (emailType, offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}/email-quota/getEmailLogs/${emailType}?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}

const getFantasyResult = async () => {
  const response = await agent.get(API_URL + 'email-quota/getFantasyResult')
  return response.data
}

const getEmailAlertCount = async () => {
  const response = await agent.get(API_URL + 'email-quota/getEmailAlertCount')
  return response.data
}

const EmailQuotaService = {
  getTransactions,
  getMemberList,
  saveEmailMembers,
  getEmailMember,
  sendAllEmails,
  getSentEmailLog,
  getMemberMessage,
  deleteAllMembers,
  topPlayerOveall,
  topManagerOverall,
  getQuotaDetails,
  getEmailLogs,
  getFantasyResult,
  getEmailAlertCount,
}

export default EmailQuotaService
