import {
  CAlert,
  CCol,
  CDatePicker,
  CForm,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CTimePicker,
} from '@coreui/react-pro'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FixtureService from 'src/service/FixtureService'
import TeamService from 'src/service/TeamService'
import { useNavigate } from 'react-router-dom'
import ToastComponent from 'src/components/common/TaostComponent'
const AddForm = (props) => {
  const [loader, setLoader] = useState(false)
  const [matchTypeList, setMatchTypeList] = useState()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [teamList, setTeamList] = useState([])
  const [errorMessages, setErrorMessages] = useState([])
  let validationSchema = Yup.object().shape({
    team: Yup.string().required('Team is required'),
    //start_date: Yup.string().nullable().required('Start Date is required'),
    //end_date: Yup.string().required('End Date is required'),
  })

  if (props.gameType !== 1) {
    validationSchema = Yup.object().shape({
      team: Yup.string().required('Team is required'),
      team2: Yup.string().required('Team2 is required'),
      //start_date: Yup.string().nullable().required('Start Date is required'),
      //end_date: Yup.string().required('End Date is required'),
    })
  }

  useEffect(() => {
    setLoader(true)
    TeamService.getTeamListByGrade().then((res) => {
      if (res.status === 200) {
        setLoader(false)
        setTeamList(res.data)
        setMatchTypeList(res.matchTypeList)
      }
    })
  }, [props.visibleHorizontal])
  const formik = useFormik({
    initialValues: {
      team: '',
      team2: '',
      match_type: '',
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
      opposition_club: '',
      vanue: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      data.start_date = startDate
      data.end_date = endDate
      data.start_time = lockoutStartTime
      data.end_time = lockoutEndTime
      setLoader(true)
      FixtureService.saveFixture(data).then((res) => {
        if (res.success) {
          setErrorMessages([])
          props.fetchData(
            props.activePage,
            props.columnFilter,
            props.columnSorter,
            props.itemsPerPage,
          )
          ToastComponent(res.message, 'success')
          setLoader(false)
        } else {
          const errors =
            res.errors && Array.isArray(res.errors)
              ? res.errors
              : [res.message || 'Something went wrong.']
          setErrorMessages(errors) // Update error messages
          setLoader(false)
        }
      })
    },
  })
  const handleStartDate = (event) => {
    setMinEndDate(event)
    const dateFormat = moment(event).format('YYYY-MM-DD')
    setStartDate(dateFormat)
    setEndDate('')
  }
  const handleEndDate = (event) => {
    const dateFormat = moment(event).format('YYYY-MM-DD')
    setEndDate(dateFormat)
  }

  const [minEndDate, setMinEndDate] = useState(new Date())
  const todayDate = new Date()
  const lastDate = minEndDate
  const minDate = todayDate.setDate(todayDate.getDate() - 1)
  const minasdasDate = lastDate.setDate(lastDate.getDate())

  const [lockoutStartTime, setLockoutStartTime] = useState('')
  const [lockoutEndTime, setLockoutEndTime] = useState('')
  const handleLockoutStartTime = (event) => {
    const dateFormat = moment(event, ['H:mm']).format('HH:mm')
    setLockoutStartTime(dateFormat)
  }
  const handleLockoutEndTime = (event) => {
    const dateFormat = moment(event, ['H:mm']).format('HH:mm')
    setLockoutEndTime(dateFormat)
  }
  return (
    <>
      {errorMessages.length > 0 && (
        <div className="error-list">
          {errorMessages.map((error, index) => (
            <>
              {/* <li key={index}>{error}</li> */}
              <CAlert color="danger" key={index}>
                {error}
              </CAlert>
            </>
          ))}
        </div>
      )}
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="team">
            {props.gameType === 1 ? 'Team' : 'Home Team'}
          </CFormLabel>
          <CFormSelect
            aria-label="Select Team"
            name="team"
            className={
              'form-control' + (formik.errors.team && formik.touched.team ? ' is-invalid' : '')
            }
            defaultValue={formik.values.team}
            onChange={formik.handleChange}
            id="team"
          >
            <option value={0}>Select {props.gameType === 1 ? 'Team' : 'Home Team'}</option>
            {teamList &&
              teamList.map((item, key) => (
                <option value={item?.id} key={key}>
                  {item?.name} ({item?.sn})
                </option>
              ))}
          </CFormSelect>
          {formik.errors.team && formik.touched.team && (
            <CFormFeedback invalid>{formik.errors.team}</CFormFeedback>
          )}
        </CCol>

        {props.gameType !== 1 && (
          <CCol md={3}>
            <CFormLabel className="fw-bold" htmlFor="team">
              Away Team *
            </CFormLabel>
            <CFormSelect
              aria-label="Select Team"
              name="team2"
              className={
                'form-control' + (formik.errors.team2 && formik.touched.team2 ? ' is-invalid' : '')
              }
              defaultValue={formik.values.team2}
              onChange={formik.handleChange}
              id="team2"
            >
              <option value={0}>Select Away Team</option>
              {teamList &&
                teamList.map((item, key) => (
                  <option value={item?.id} key={key}>
                    {item?.name} ({item?.sn})
                  </option>
                ))}
            </CFormSelect>
            {formik.errors.team2 && formik.touched.team2 && (
              <CFormFeedback invalid>{formik.errors.team2}</CFormFeedback>
            )}
          </CCol>
        )}

        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="Start Date">
            Start Date *
          </CFormLabel>
          <CDatePicker
            date={startDate}
            defaultValue={startDate}
            className={formik.errors.start_date && formik.touched.start_date ? 'is-invalid' : ''}
            locale="en-US"
            name="start_date"
            placeholder={'Start Date'}
            onDateChange={(e) => {
              handleStartDate(e)
              formik.setTouched({
                ...formik.touched,
                start_date: true,
              })
              formik.setFieldValue('start_date', moment(e).format('YYYY-MM-DD'))
            }}
            format={'dd/MM/yyyy'}
            // minDate={todayDate}
            cleaner={false}
          />

          {formik.errors.start_date && formik.touched.start_date && (
            <CFormFeedback invalid>{formik.errors.start_date}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="End Date">
            End Date *
          </CFormLabel>
          <CDatePicker
            date={endDate}
            defaultValue={endDate}
            className={formik.errors.end_date && formik.touched.end_date ? 'is-invalid' : ''}
            locale="en-US"
            name="end_date"
            placeholder={'End Date'}
            // onDateChange={handleEndDate}
            onDateChange={(e) => {
              handleEndDate(e)
              formik.setTouched({
                ...formik.touched,
                end_date: true,
              })
              formik.setFieldValue('end_date', moment(e).format('YYYY-MM-DD'))
            }}
            format={'dd/MM/yyyy'}
            cleaner={false}
            // minDate={minEndDate}
          />

          {formik.errors.end_date && formik.touched.end_date && (
            <CFormFeedback invalid>{formik.errors.end_date}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="start_time">
            Start Time *
          </CFormLabel>
          <CTimePicker
            locale="en-US"
            value={lockoutStartTime}
            seconds={false}
            // onTimeChange={handleLockoutStartTime}
            className={formik.errors.start_time && formik.touched.start_time ? 'is-invalid' : ''}
            onTimeChange={(e) => {
              handleLockoutStartTime(e)
              formik.setTouched({
                ...formik.touched,
                start_time: true,
              })
              formik.setFieldValue('start_time', moment(e).format('YYYY-MM-DD'))
            }}
            // ampm={false}
          />

          {formik.errors.star_time && formik.touched.star_time && (
            <CFormFeedback invalid>{formik.errors.star_time}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="endTime">
            End Time *
          </CFormLabel>
          <CTimePicker
            className={formik.errors.end_time && formik.touched.end_time ? 'is-invalid' : ''}
            locale="en-US"
            value={lockoutEndTime}
            seconds={false}
            onTimeChange={(e) => {
              handleLockoutEndTime(e)
              formik.setTouched({
                ...formik.touched,
                end_time: true,
              })
              formik.setFieldValue('end_time', moment(e).format('YYYY-MM-DD'))
            }}
            // ampm={false}
          />

          {formik.errors.end_time && formik.touched.end_time && (
            <CFormFeedback invalid>{formik.errors.end_time}</CFormFeedback>
          )}
        </CCol>
        <CCol md={3}>
          <CFormLabel className="fw-bold" htmlFor="alast_name">
            Venue
          </CFormLabel>
          <input
            type="text"
            name="vanue"
            className={
              'form-control' + (formik.errors.vanue && formik.touched.vanue ? ' is-invalid' : '')
            }
            id="vanue"
            placeholder="Venue"
            defaultValue={formik.values.vanue}
            onChange={formik.handleChange}
          />
          {formik.errors.vanue && formik.touched.vanue && (
            <CFormFeedback invalid>{formik.errors.vanue}</CFormFeedback>
          )}
        </CCol>
        {props.gameType === 1 && (
          <CCol md={3}>
            <CFormLabel className="fw-bold" htmlFor="alast_name">
              Opponent
            </CFormLabel>
            <input
              type="text"
              name="opposition_club"
              className={
                'form-control' +
                (formik.errors.opposition_club && formik.touched.opposition_club
                  ? ' is-invalid'
                  : '')
              }
              id="opposition_club"
              placeholder="Opponent"
              defaultValue={formik.values.opposition_club}
              onChange={formik.handleChange}
            />
            {formik.errors.opposition_club && formik.touched.opposition_club && (
              <CFormFeedback invalid>{formik.errors.opposition_club}</CFormFeedback>
            )}
          </CCol>
        )}
        {props.gameType !== 1 && (
          <>
            <CCol md={9}></CCol>
          </>
        )}
        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default AddForm
