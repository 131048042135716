import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CMultiSelect,
} from '@coreui/react-pro'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ToastComponent from 'src/components/common/TaostComponent'
import GameNotificationservice from 'src/service/GameNotificationService'
import EmailQuotaService from 'src/service/EmailQuotaService'
import EmailMemberListing from './EmailMemberListing'
const CreatEmailMember = (props) => {
  const [memberList, setMemberList] = useState([])
  const [selectedValue, setSelectedValue] = useState([])
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : [])
  }
  useEffect(() => {
    EmailQuotaService.getMemberList().then((res) => {
      if (res.status === 200) {
        setMemberList(res.data)
      }
    })
  }, [])

  const validationSchema = Yup.object().shape({
    members: Yup.array().min(1, 'At least one member is required').required('Members are required'), // Optional, as min(1) covers it
    message: Yup.string().required('Message is required'),
  })
  const formik = useFormik({
    initialValues: {
      members: [],
      message: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data) => {
      // data.members = selectedValue
      EmailQuotaService.saveEmailMembers(data)
        .then((res) => {
          if (res.status === 200) {
            fetchUsers()
            ToastComponent(res.message, 'success')
          } else {
            ToastComponent(res.message, 'error')
          }
        })
        .catch((e) => {
          ToastComponent('Something Went wront. Please try again.', 'error')
        })
    },
  })

  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState({})
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [users, setUsers] = useState(props.users)
  const [details, setDetails] = useState([])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const [sentEmailIds, setSentEmailIds] = useState([])
  const fetchUsers = useCallback(async () => {
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    try {
      const result = await EmailQuotaService.getEmailMember(
        offset,
        itemsPerPage,
        activePage,
        params,
      )
      setUsers(result.data)
      setSentEmailIds(result.sent_email_ids)
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
    }
  }, [activePage, itemsPerPage, columnFilter, columnSorter])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>Setup</CCardHeader>
        <CCardBody>
          <CForm className="row g-3" onSubmit={formik.handleSubmit}>
            <CCol md={12}>
              <CFormLabel className="fw-bold" htmlFor="title">
                Select Members
              </CFormLabel>
              <CMultiSelect
                id="members"
                options={memberList}
                selectionType="tags"
                name="members"
                onChange={(selected) => {
                  formik.setFieldValue('members', selected)
                  formik.setFieldTouched('members', true) // Manually mark as touched
                }}
                value={formik.values.members}
                invalid={formik.touched.members && !!formik.errors.members} // Apply invalid state
              />
              <CFormFeedback invalid>
                {formik.touched.members && formik.errors.members}
              </CFormFeedback>
            </CCol>
            <CCol md={12}>
              <CFormLabel className="fw-bold" htmlFor="message">
                Add Message
              </CFormLabel>
              <textarea
                name="message"
                id="message"
                className={
                  'form-control' +
                  (formik.errors.message && formik.touched.message ? ' is-invalid' : '')
                }
                placeholder="Message"
                defaultValue={formik.values.message}
                onChange={formik.handleChange}
              />
              {formik.errors.message && formik.touched.message && (
                <CFormFeedback invalid>{formik.errors.message}</CFormFeedback>
              )}
            </CCol>
            <CCol md={6}>
              <CLoadingButton type="submit" color="success" variant="outline" id="submit">
                Save Message
              </CLoadingButton>
            </CCol>
          </CForm>
        </CCardBody>
      </CCard>
      <EmailMemberListing
        details={details}
        setUsers={setUsers}
        setDetails={setDetails}
        selectedCheckboxes={selectedCheckboxes}
        setSelectedCheckboxes={setSelectedCheckboxes}
        users={users}
        fetchUsers={fetchUsers}
        setActivePage={setActivePage}
        setItemsPerPage={setItemsPerPage}
        setColumnFilter={setColumnFilter}
        setColumnSorter={setColumnSorter}
        itemsPerPage={itemsPerPage}
        activePage={activePage}
        columnFilter={columnFilter}
        columnSorter={columnSorter}
        sentEmailIds={sentEmailIds}
      />
    </>
  )
}

export default CreatEmailMember
