import {
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CCol,
  CFormSelect,
  CButton,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import PlayerProfileListing from './PlayerProfileListing'
import ClubService from 'src/service/ClubService'
import MemberInviteUserList from './MemberInviteUserList'
import ToastComponent from 'src/components/common/TaostComponent'

const MemberInvites = () => {
  const [loading, setLoading] = useState(false)
  const [isListingShow, setIsListingShow] = useState(false)
  const [gameId, setGameId] = useState(false)
  const [users, setUsers] = useState([])
  const [gameList, setGameList] = useState(false)
  useEffect(() => {
    setLoading(true)
    ClubService.getGames().then((res) => {
      if (res.status === 200) {
        setGameList(res.data)
        setLoading(false)
      }
    })
  }, [])
  const handleGameChange = (e) => {
    let gameId = e.target.value
    setGameId(gameId)
    ClubService.getUserFromGame(gameId).then((res) => {
      if (res.status == 200) {
        setUsers(res.data)
      }
    })
    setIsListingShow(true)
  }
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const sendAllInvitaitonSelectedUser = () => {
    if (selectedCheckboxes.length <= 0) {
      ToastComponent('Please selct users', 'error')
    }
    const payload = { userIds: selectedCheckboxes, gameId: gameId }

    ClubService.sendMemberInvite(payload).then((res) => {
      if (res.status == 200) {
        ToastComponent(res.message, 'success')
      }
    })
  }
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={2}>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>
              {' '}
              <strong>Invite Member From Game</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CRow>
                <CCol md={4}>
                  <CFormSelect
                    aria-label="Select Game"
                    name="game"
                    className={'form-control'}
                    value={gameId}
                    onChange={handleGameChange}
                    id="game"
                  >
                    <option value={0}>Select Game</option>
                    {gameList &&
                      gameList.map((item, key) => (
                        <option value={item?.id} key={key}>
                          {item?.game_name}
                        </option>
                      ))}
                  </CFormSelect>
                </CCol>
              </CRow>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
      <CRow>
        <CAccordion activeItemKey={2}>
          <CAccordionItem itemKey={2}>
            <CAccordionHeader>
              {' '}
              <strong>Select Game Members</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CCol md={4}>
                {' '}
                <CButton
                  type="submit"
                  color="success"
                  variant="outline"
                  onClick={sendAllInvitaitonSelectedUser}
                >
                  Send Invites
                </CButton>
              </CCol>
              {isListingShow && (
                <MemberInviteUserList
                  gameId={gameId}
                  selectedCheckboxes={selectedCheckboxes}
                  setSelectedCheckboxes={setSelectedCheckboxes}
                />
              )}
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default MemberInvites
