import { CButton } from '@coreui/react-pro'
import React from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import EmailQuotaService from 'src/service/EmailQuotaService'

const TopTenManagerEmail = (props) => {
  const sendEmails = () => {
    EmailQuotaService.topManagerOverall().then((res) => {
      if (res.success) {
        ToastComponent(res.message, 'success')
      } else {
        ToastComponent(res.message, 'error')
      }
    })
  }
  return (
    <>
      <table className="main-table table innertable">
        <thead>
          <tr>
            <th width="30%">Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Top 10 Managers (Overall)</td>
            <td>
              <CButton onClick={() => sendEmails()}>Send Now</CButton>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default TopTenManagerEmail
