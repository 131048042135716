import React, { useEffect, useRef, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCollapse,
  CSmartTable,
} from '@coreui/react-pro'
import PlayerService from 'src/service/PlayerService'
import UpdateImage from './UpdateImage'
import ClubService from 'src/service/ClubService'
import moment from 'moment'
import ToastComponent from 'src/components/common/TaostComponent'
const MemberInviteUserList = (props) => {
  const [loading, setLoading] = useState()
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [users, setUsers] = useState([])
  const [userIds, setUserIds] = useState([])

  // const toggleAllCheckboxes = () => {
  //   const allIds = users.map((user) => user?.id)
  //   props?.setSelectedCheckboxes((prevSelected) => (prevSelected?.length === allIds?.length ? [] : allIds))
  // }

  const toggleAllCheckboxes = () => {
    const allIds = Array.isArray(users?.data) ? users.data.map((user) => user?.id) : []
    props?.setSelectedCheckboxes((prevSelected) =>
      prevSelected.length === allIds.length ? [] : allIds,
    )
  }

  const parentCheckboxRef = useRef(null)

  useEffect(() => {
    if (parentCheckboxRef.current) {
      parentCheckboxRef.current.indeterminate =
        props?.selectedCheckboxes.length > 0 &&
        props?.selectedCheckboxes.length < users?.data?.length
    }
  }, [props?.selectedCheckboxes, users?.data?.length])
  const columns = [
    {
      key: 'delete_fixture',
      label: (
        <input
          type="checkbox"
          name="delete_all"
          ref={parentCheckboxRef}
          checked={
            props?.selectedCheckboxes.length > 0 &&
            props?.selectedCheckboxes.length === users?.data?.length
          }
          onChange={toggleAllCheckboxes}
        />
      ),
      filter: false,
      sorter: false,
    },

    {
      label: 'First Name',
      key: 'first_name',
    },
    {
      label: 'Last Name',
      key: 'last_name',
    },
    {
      label: 'Email',
      key: 'email',
    },
    { label: 'Created On', filter: false, key: 'created_at' },
    { label: 'Sent', filter: false, sorter: true, key: 'is_sent' },
    {
      key: 'actions',
      label: 'Invite',
      filter: false,
      sorter: false,
    },
  ]

  useEffect(() => {
    if (!props?.gameId) {
      return
    }

    setLoading(true)
    const offset = itemsPerPage * (activePage - 1)
    const params = new URLSearchParams()

    // Add filters to params
    if (columnFilter && Object.keys(columnFilter).length > 0) {
      Object.keys(columnFilter).forEach((key) => {
        params.append(key, columnFilter[key])
      })
    }

    // Add sorter to params
    if (columnSorter && columnSorter.column) {
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)
    }

    ClubService.getUserFromGame(offset, itemsPerPage, activePage, params, props?.gameId)
      .then((result) => {
        setUsers(result.data)
      })
      .catch((error) => {
        console.error('Error fetching users:', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [activePage, columnFilter, columnSorter, itemsPerPage, props?.gameId])

  const toggleCheckbox = (id) => {
    props?.setSelectedCheckboxes((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  const sendCode = (id) => {
    const payload = { userIds: [id], gameId: props.gameId }
    ClubService.sendMemberInvite(payload).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
        props.fetchData()
      }
    })
  }
  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 0:
        return 'danger'
      default:
        return 'primary'
    }
  }
  return (
    <>
      <CSmartTable
        columns={columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          delete_fixture: (item) => (
            <th>
              <input
                type="checkbox"
                name="delete"
                value={item.id}
                checked={props?.selectedCheckboxes.includes(item.id)}
                onChange={() => toggleCheckbox(item.id)}
              />
            </th>
          ),
          created_at: (item) => <td> {moment(item.updated_at).format('D.MM.YYYY')}</td>,
          is_sent: (item) => (
            <td>
              <CBadge color={getBadge(item.is_sent)}> {item.is_sent === 1 ? 'Yes' : 'No'}</CBadge>
            </td>
          ),
          actions: (item) => (
            <td>
              {item?.is_sent === 0 && (
                <CButton color={'success'} size="sm" onClick={() => sendCode(item.id)}>
                  Send
                </CButton>
              )}
              {item?.is_sent === 1 && (
                <CButton color={'success'} size="sm" onClick={() => sendCode(item.id)}>
                  Re-send
                </CButton>
              )}
              {item?.is_sent === 2 && (
                <CButton
                  color={'success'}
                  size="sm"
                  // onClick={() => props.handleDeleteRequest(item.id)}
                >
                  Joined
                </CButton>
              )}
            </td>
          ),
        }}
        items={users?.data}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        loading={loading}
        pagination={{
          external: true,
        }}
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(users?.total / itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage(1)
          setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1)
          setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => setColumnSorter(sorter)}
      />
    </>
  )
}

export default MemberInviteUserList
