import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CAlert,
} from '@coreui/react-pro'
import Table from './Table'
import AddForm from './AddForm'
import { useEffect } from 'react'
import FixtureService from 'src/service/FixtureService'
import ToastComponent from 'src/components/common/TaostComponent'
import CommonService from 'src/service/CommonService'
const Fixture = () => {
  const [displayDetails, setDisplayDetails] = useState([])
  const [users, setUsers] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState([])
  const [columnSorter, setColumnSorter] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  const [loading, setLoading] = useState()
  const [gameType, setGameType] = useState()
  var columns = [
    {
      label: 'FID',
      key: 'id',
      _style: { width: '10%' },
    },
    {
      key: 'round',
    },
    {
      label: 'Team',
      key: 'team_name',
    },
    { label: 'Start Date', filter: false, key: 'start_date' },
    { label: 'Start Time', filter: false, key: 'start_time' },
    { label: 'Status', filter: false, key: 'status' },
    {
      key: 'display',
      label: 'Display',
      filter: false,
      sorter: false,
    },
    {
      key: 'show_details',
      label: 'Actions',
      filter: false,
      sorter: false,
    },
  ]
  if (gameType !== 1) {
    columns = [
      {
        label: 'FID',
        key: 'id',
        _style: { width: '10%' },
      },
      {
        key: 'round',
      },
      {
        label: 'Home Team',
        key: 'team_name',
      },
      {
        label: 'Away Team',
        key: 'team2_name',
      },
      { label: 'Start Date', filter: false, key: 'start_date' },
      { label: 'Start Time', filter: false, key: 'start_time' },
      { label: 'Status', filter: false, key: 'status' },
      {
        key: 'display',
        label: 'Display',
        filter: false,
        sorter: false,
      },
      {
        key: 'show_details',
        label: 'Actions',
        filter: false,
        sorter: false,
      },
    ]
  }

  useEffect(() => {
    fetchData(activePage, columnFilter, columnSorter, itemsPerPage)
  }, [activePage, columnFilter, columnSorter, itemsPerPage])

  const fetchData = (activePage, columnFilter, columnSorter, itemsPerPage) => {
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    FixtureService.getFixture(offset, itemsPerPage, activePage, params).then((result) => {
      setUsers(result.data)
      setLoading(false)
    })
  }
  useEffect(() => {
    FixtureService.getActivatedDisplay()
      .then((res) => {
        if (res.status === 200) {
          setDisplayDetails(res.data)
        }
      })
      .then(() => {
        CommonService.getGameType().then((res) => {
          if (res.status === 200) {
            setGameType(res.data)
          }
        })
      })
  }, [])
  return (
    <CRow>
      <CAccordion activeItemKey={1} alwaysOpen>
        {gameType == 4 && (
          <CAlert color="danger">Restriction: You can create a maximum of 1 Fixture only</CAlert>
        )}
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Create Fixtures</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <AddForm
              users={users}
              activePage={activePage}
              setActivePage={setActivePage}
              columnFilter={columnFilter}
              setColumnFilter={setColumnFilter}
              columnSorter={columnSorter}
              setColumnSorter={setColumnSorter}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              columns={columns}
              fetchData={fetchData}
              setLoading={setLoading}
              loading={loading}
              gameType={gameType}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            {' '}
            <strong>Manage Fixtures</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <Table
              displayDetails={displayDetails}
              setDisplayDetails={setDisplayDetails}
              users={users}
              activePage={activePage}
              setActivePage={setActivePage}
              columnFilter={columnFilter}
              setColumnFilter={setColumnFilter}
              columnSorter={columnSorter}
              setColumnSorter={setColumnSorter}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              columns={columns}
              fetchData={fetchData}
              setLoading={setLoading}
              loading={loading}
              gameType={gameType}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CRow>
  )
}

export default Fixture
