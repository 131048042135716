import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCollapse,
  CSmartTable,
} from '@coreui/react-pro'

import moment from 'moment'
import ToastComponent from 'src/components/common/TaostComponent.js'
import TeamService from 'src/service/TeamService'
const Table = ({
  gradeList,
  teamCategoryList,
  users,
  setUsers,
  fetchUsers,
  setActivePage,
  setItemsPerPage,
  setColumnFilter,
  setColumnSorter,
  itemsPerPage,
  activePage,
  columnFilter,
  columnSorter,
}) => {
  const [loading, setLoading] = useState()
  const [details, setDetails] = useState([])
  const columns = [
    {
      label: 'SN',
      key: 'sn',
    },
    {
      label: 'Invoice',
      key: 'invoice',
    },
    {
      label: 'Amount',
      key: 'fee',
    },
    {
      label: 'Quota',
      key: 'quota',
    },
    {
      label: 'Paid On',
      key: 'created_at',
    },
  ]

  useEffect(() => {
    const offset = itemsPerPage * activePage - itemsPerPage
    fetchUsers(offset, itemsPerPage, activePage, columnFilter, columnSorter)
  }, [activePage, columnFilter, columnSorter, itemsPerPage, fetchUsers])
  return (
    <>
      <CSmartTable
        columns={columns}
        columnFilter={{
          external: true,
        }}
        columnSorter={{
          external: true,
        }}
        scopedColumns={{
          sn: (item, index) => <td>{index + 1}</td>,
          fee: (item, index) => <td>${item.fee}</td>,
          quota: (item, index) => <td>5000</td>,
          invoice: (item) => (
            <td>
              <a
                href={`${process.env.REACT_APP_API_URL}uploads/plan-invoices/${item.invoice_link}`}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
            </td>
          ),
          created_at: (item) => <td>{moment(item.created_at).format('D.MM.YYYY')}</td>,
        }}
        items={users?.data}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        loading={loading}
        pagination={{
          external: true,
        }}
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(users?.total / itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage(1)
          setColumnFilter(filter)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1)
          setItemsPerPage(itemsPerPage)
        }}
        onSorterChange={(sorter) => setColumnSorter(sorter)}
      />
    </>
  )
}

export default Table
