import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL
const getClubDetails = async (data) => {
  const response = await agent.get(API_URL + 'get-club-details')
  return response.data
}

const getStateByCountryId = async (countryId) => {
  const response = await agent.get(`${API_URL}get-state-by-country-id/${countryId}`)
  return response.data
}
const updateGameAdmin = async (data) => {
  const response = await agent.post(API_URL + 'update-game-admin', data)
  return response.data
}

const updateGameSocial = async (data) => {
  const response = await agent.post(API_URL + 'update-game-social', data)
  return response.data
}
const getUserTimezone = async (data) => {
  const response = await agent.get(API_URL + 'getUserTimezone')
  return response.data
}
const updateGameIntro = async (data) => {
  const response = await agent.post(API_URL + 'update-game-intro', data)
  return response.data
}
const updateAboutGame = async (data) => {
  const response = await agent.post(API_URL + 'update-about-game', data)
  return response.data
}
const updateFeeInfo = async (data) => {
  const response = await agent.post(API_URL + 'update-fee-info', data)
  return response.data
}
const updateBasicSetting = async (data) => {
  const response = await agent.post(API_URL + 'update-basic-setting', data)
  return response.data
}
const updateGameStatus = async (data) => {
  const response = await agent.post(API_URL + 'updateGameStatus', data)
  return response.data
}
const transferAdminRight = async (data) => {
  const response = await agent.post(API_URL + 'transferAdminRight', data)
  return response.data
}
const createNewClub = async (data) => {
  const response = await agent.post(API_URL + 'createNewClub', data)
  return response.data
}
const updateTermCondition = async (data) => {
  const response = await agent.post(API_URL + 'updateTermCondition', data)
  return response.data
}
const updateAccount = async (data) => {
  const response = await agent.post(API_URL + 'updateAccount', data)
  return response.data
}
const getWelcomeMessage = async (countryId) => {
  const response = await agent.get(`${API_URL}getWelcomeMessage`)
  return response.data
}

const setWelcomeMessage = async (data) => {
  const response = await agent.post(`${API_URL}setWelcomeMessage`, data)
  return response.data
}
const getGames = async () => {
  const response = await agent.get(`${API_URL}getGames`)
  return response.data
}
// const getUserFromGame = async (gameId) => {
//   const response = await agent.get(`${API_URL}getUserFromGame/${gameId}`)
//   return response.data
// }

const getUserFromGame = async (offset, itemsPerPage, activePage, params, gameId) => {
  if (typeof gameId !== 'undefined') {
    const response = await agent.get(
      `${API_URL}getUserFromGame/${gameId}?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
    )
    return response.data
  }
}

const sendMemberInvite = async (data) => {
  const response = await agent.post(API_URL + 'sendMemberInvite', data)
  return response.data
}

const ClubService = {
  getClubDetails,
  updateAccount,
  updateGameAdmin,
  updateGameSocial,
  updateGameIntro,
  getStateByCountryId,
  updateAboutGame,
  updateFeeInfo,
  updateBasicSetting,
  updateGameStatus,
  transferAdminRight,
  createNewClub,
  updateTermCondition,
  getUserTimezone,
  getWelcomeMessage,
  setWelcomeMessage,
  getGames,
  getUserFromGame,
  sendMemberInvite,
}

export default ClubService
