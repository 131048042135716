import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CAlert,
  CCol,
  CLink,
  CRow,
  CWidgetStatsC,
  CWidgetStatsF,
} from '@coreui/react-pro'
import React, { useCallback, useEffect, useState } from 'react'
import EmailQuotaService from 'src/service/EmailQuotaService'
import Table from './Table'
import EmailQuotaDetails from './EmailQuotaDetails'

const PurchaseEmail = () => {
  useEffect(() => {
    // Ensure the Stripe script is loaded before using the Stripe pricing table
    const script = document.createElement('script')
    script.src = 'https://js.stripe.com/v3/pricing-table.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const [users, setUsers] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [columnFilter, setColumnFilter] = useState({})
  const [columnSorter, setColumnSorter] = useState({})
  const fetchUsers = useCallback(async () => {
    const offset = itemsPerPage * activePage - itemsPerPage
    let params = new URLSearchParams()
    Object.keys(columnFilter).forEach((key) => {
      params.append(key, columnFilter[key])
    })
    columnSorter &&
      columnSorter.column !== undefined &&
      params.append('sort', `${columnSorter.column}%${columnSorter.state}`)

    try {
      const result = await EmailQuotaService.getTransactions(
        offset,
        itemsPerPage,
        activePage,
        params,
      )
      setUsers(result.data)
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }, [activePage, itemsPerPage, columnFilter, columnSorter])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  return (
    <>
      <EmailQuotaDetails />
      <CRow>
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Purchase Email Quota</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <stripe-pricing-table
                pricing-table-id="prctbl_1QpUFGItsmZf8AkXQkCtM2tR"
                publishable-key="pk_live_I2ZzJhqZwGTxEprRPJwLt4TE"
              ></stripe-pricing-table>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
        <CAccordion activeItemKey={1} alwaysOpen>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Transaction</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <Table
                users={users}
                setUsers={setUsers}
                fetchUsers={fetchUsers}
                setActivePage={setActivePage}
                setItemsPerPage={setItemsPerPage}
                setColumnFilter={setColumnFilter}
                setColumnSorter={setColumnSorter}
                itemsPerPage={itemsPerPage}
                activePage={activePage}
                columnFilter={columnFilter}
                columnSorter={columnSorter}
              />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
    </>
  )
}

export default PurchaseEmail
